// @ts-nocheck
import React from 'react'
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

// @ts-ignore
const configJSON = require("./config.js");

//content file
// @ts-ignore
import content from "../../../studio-store-restaurant-theme/src/AppWebContent";

export interface Props {
  history?: any;
}
export interface S {
  searchQuery: string;
  headerScrolled: boolean;
  isLoggedIn?: boolean;
  isOpenLocation?: boolean;
  searchDropDown: boolean;
  quickResults?: Array<any>;
  searchStore: string;
  wishlistLength: any;
  userInfoData: any;
  isShowSearchBar: boolean;
  currentPage: number;

  //cart state
  cartItemsLength: any;

  //mobile menu state
  isMobileMenu: boolean;
  mobileMenuItems: Array<any>;
}
export interface SS { }
let isErrorMessageShowOnce = true
export default class HeaderController extends BlockComponent<Props, S, SS> {
  getLiveSearchApiCallId: string;
  getCartApiCallId: string;
  getFavoriteListCallId: string;
  userProfileGetApiCallId: string;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.UpdateWishlist),
      getName(MessageEnum.UpdateCartListApiMessage),
      getName(MessageEnum.UserInfoApiMessage),
      getName(MessageEnum.ShowSearchBarApiMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      searchQuery: "",
      headerScrolled: false,
      isMobileMenu: false,
      searchDropDown: false,
      quickResults: [],
      cartItemsLength: 0,
      isFavoriteHasItems: [],
      wishlistLength: 0,
      userInfoData: {},
      isShowSearchBar: true,
      currentPage: 1,
      mobileMenuItems: [
        { tabTitle: "My Favorites", tabId: "2" },
        { tabTitle: "Reviews", tabId: "3" },
        { tabTitle: "My Orders", tabId: "4" },
        // { tabTitle: "My Bookings", tabId: "5" },
        { tabTitle: "Saved Addresses", tabId: "6" },
        { tabTitle: "Help Center", tabId: "7" },
        { tabTitle: "Contact Us", tabId: "8" },
        { tabTitle: "Notifications", tabId: "9" },
        { tabTitle: "Logout", tabId: "10" },
      ],
      searchStore: ""
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }



  componentDidMount() {

    const userData = localStorage.getItem("userData");
    if (userData) {
      this.setState({
        isLoggedIn: true,
      });
    }
    this.getCartItems();
    this.getFavoriteListItems();
    if (this.state.userInfoData)
      this.getUserProfile()
    window.addEventListener("scroll", this.listenScrollEvent.bind(this));
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent.bind(this));
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.state.searchDropDown && this.state.searchDropDown && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        searchQuery: "",
        searchDropDown: false
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps) {
      const userData = localStorage.getItem("userData");
      const userCompletedInfo = localStorage.getItem("userCompleteInfo");
      const registartionNotCompleted = localStorage.getItem(
        "registrationDetails"
      );
      const isGuest = localStorage.getItem("guestUserID");
      if (userData && userCompletedInfo) {
        this.setState({
          isLoggedIn: true,
        });
      }
      if (isGuest) {
        this.setState({
          isLoggedIn: false,
        });
      }
      if (isGuest && userCompletedInfo) {
        this.setState({
          isLoggedIn: true,
        });
      }
      if (userData && registartionNotCompleted) {
        this.setState({
          isLoggedIn: false,
        });
      }
    }
  }
  async listenScrollEvent(event: any) {
    if (window.scrollY < 73) {
      this.setState({
        headerScrolled: false,
      });
    } else if (window.scrollY > 250) {
      this.setState({
        headerScrolled: true,
      });
    }
  }

  //Mobile Menu
  toogleMobileMenu = () => {
    this.setState({
      isMobileMenu: !this.state.isMobileMenu,
    });
  };

  //check API's Reponses function
  async receive(form: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (message.id === getName(MessageEnum.UpdateWishlist)) {
      const UpdateWishlistLen = message.getData(
        getName(MessageEnum.UpdateWishlistLen)
      );
      this.setState({
        wishlistLength: UpdateWishlistLen
      })
    }
    if (message.id === getName(MessageEnum.UpdateCartListApiMessage)) {
      const UpdateCartListLen = message.getData(
        getName(MessageEnum.UpdateCartListApiResponseMessage)
      );
      this.setState({
        cartItemsLength: UpdateCartListLen
      })
    }
    if (message.id === getName(MessageEnum.UserInfoApiMessage)) {
      const userInfoData = message.getData(
        getName(MessageEnum.UserInfoApiResponseMessage)
      );
      this.setState({
        userInfoData: userInfoData
      })
    }
    // if (message.id === getName(MessageEnum.ShowSearchBarApiMessage)) {
    //   const isShowSearchBar = message.getData(
    //     getName(MessageEnum.ShowSearchBarApiResponseMessage)
    //   );
    //   this.setState({
    //     isShowSearchBar: isShowSearchBar
    //   })
    // }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJSON && responseJSON.data) {
        if (apiRequestCallID !== null) {
          //get live searches
          if (apiRequestCallID === this.getLiveSearchApiCallId) {
            const { meta } = responseJSON.data
            this.setState({
              currentPage: meta?.pagination?.next_page,
              quickResults: responseJSON.data?.catalogues,
            });
          }

          //get Cart Items
          if (apiRequestCallID === this.userProfileGetApiCallId) {
            let userInfoData: any;
            if (responseJSON.data?.attributes?.type == "SmsAccount") {
              userInfoData = {
                email: responseJSON.data?.attributes?.full_phone_number?.slice(2),
                image: responseJSON.data?.attributes?.image_url,
                name: responseJSON.data?.attributes?.full_name,
              };
            }
            else {
              userInfoData = {
                email: responseJSON.data?.attributes?.email,
                image: responseJSON.data?.attributes?.image_url,
                name: responseJSON.data?.attributes?.full_name,
              };
            }
            let updateUserInfoMessage = new Message(
              getName(MessageEnum.UserInfoApiMessage)
            );
            updateUserInfoMessage.addData(
              getName(MessageEnum.UserInfoApiResponseMessage),
              userInfoData
            );
            runEngine.sendMessage(
              updateUserInfoMessage.id,
              updateUserInfoMessage
            );
          }
          if (apiRequestCallID === this.getCartApiCallId) {
            localStorage.setItem(
              "cartDataLen",
              responseJSON?.data?.order?.data?.attributes?.order_items
                ?.length
            );
            let cartListUpdateMessage = new Message(
              getName(MessageEnum.UpdateCartListApiMessage)
            );
            cartListUpdateMessage.addData(
              getName(MessageEnum.UpdateCartListApiResponseMessage),
              responseJSON?.data?.order?.data?.attributes?.order_items
                ?.length
            );
            runEngine.sendMessage(
              cartListUpdateMessage.id,
              cartListUpdateMessage
            );
          }

          if (apiRequestCallID === this.getFavoriteListCallId) {
            localStorage.setItem(
              "wishlist_len",
              responseJSON?.data?.wishlist?.data?.attributes?.wishlist_items
                ?.length
            );
            var wishlistUpdateMessage = new Message(
              getName(MessageEnum.UpdateWishlist)
            );
            wishlistUpdateMessage.addData(
              getName(MessageEnum.UpdateWishlistLen),
              responseJSON?.data?.wishlist?.data?.attributes?.wishlist_items
                ?.length
            );
            runEngine.sendMessage(
              wishlistUpdateMessage.id,
              wishlistUpdateMessage
            );
          }

        }
      }

      if (isErrorMessageShowOnce) {
        if (errorMessage) {
          window.notify([
            { type: "danger", message: errorMessage || content.someThingWent },
          ]);
        }
        isErrorMessageShowOnce = false
      }

    }
  }

  //push recent search param
  pushParams = () => {
    let urlSearch = new URLSearchParams(window.location.search);
    urlSearch.delete("category_id");
    urlSearch.delete("favourite");
    urlSearch.set("q[name]", this.state.searchQuery);
    this.props.history.push(`/?${decodeURIComponent(urlSearch.toString())}`);
  };

  /// get search live
  onHandleManageSearch = (e: any) => {
    this.setState({
      searchQuery: e.target.value,
      searchDropDown: true
    })
    setTimeout(() => {
      if (this.state.searchQuery != "")
        this.getLiveSearch()
    }, 300)
  }

  getLiveSearch = (): boolean => {
    const headers = {
      "Content-Type": configJSON.filterContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLiveSearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointApiGetLiveSearch}?isWeb=${true}&q[name]=${this.state.searchQuery}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //get cart info
  getCartItems = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCartApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCartAPIEndPoint);
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getUserProfile = async () => {
    this.userProfileGetApiCallId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getUserProfileAPIEndPoint,
    });
  }

  getFavoriteListItems = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getFavoriteListCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getFavoriteListAPIEndPoint);
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = await localStorage.getItem("token") || "";
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
}
